
import { useState, useEffect } from 'react';

interface YouTubeVideo {
  id: string;
  title: string;
  thumbnail: string;
}

// Cache key for localStorage
const CACHE_KEY = 'youtube_data_cache';
// Set cache expiry to 12 hours (half a day) to refresh twice daily
const CACHE_EXPIRY = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

// Updated hardcoded fallback with the latest video from the playlist
const FALLBACK_VIDEO = {
  id: 'l6k50uZAZdY', // Updated to the latest video in the playlist
  title: 'Latest Beat Preview',
  thumbnail: 'https://i.ytimg.com/vi/l6k50uZAZdY/hqdefault.jpg'
};

// Multiple API keys to rotate between in case of quota limits
const API_KEYS = [
  'AIzaSyC3InJ6m9qBjfrN-5yYARv1LAyouGGw5-I', // Original key
  'AIzaSyD_HMEbLyCgQBgMJl9JyAPxRXxFtFrCl24', // Additional key 1
  'AIzaSyAQJvY2d_FG8F9D-Hgi_UH6jOgKL-yULRk'  // Additional key 2
];

export function useYouTubeData() {
  const [latestVideo, setLatestVideo] = useState<YouTubeVideo | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchYouTubeData = async () => {
      try {
        // Check cache first
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
          const { video, timestamp } = JSON.parse(cachedData);
          // Use cache if it's less than 12 hours old (refresh twice daily)
          if (Date.now() - timestamp < CACHE_EXPIRY) {
            setLatestVideo(video);
            setLoading(false);
            return;
          }
        }

        setLoading(true);
        const playlistId = 'PLz6mca9t9ZdzYWRnkFQGohjZJtyj6duvb';
        
        // Try each API key until one works
        let success = false;
        for (const apiKey of API_KEYS) {
          if (success) break;
          
          try {
            // Set maxResults higher to ensure we get the most recent videos, and specify order by date
            const url = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${playlistId}&maxResults=5&order=date&key=${apiKey}`;
            
            const response = await fetch(url);
            
            if (!response.ok) {
              console.log(`YouTube API error with key ${apiKey}: ${response.status}`);
              continue; // Try next API key
            }
            
            const data = await response.json();
            
            if (data.items && data.items.length > 0) {
              // Get the first item which should be the most recent with order=date
              const videoData = data.items[0].snippet;
              const video = {
                id: videoData.resourceId.videoId,
                title: videoData.title,
                thumbnail: videoData.thumbnails.high?.url || videoData.thumbnails.default.url
              };
              
              setLatestVideo(video);
              
              // Cache the result with current timestamp
              localStorage.setItem(CACHE_KEY, JSON.stringify({
                video,
                timestamp: Date.now()
              }));
              
              success = true;
              break; // Exit the loop as we got data successfully
            }
          } catch (err) {
            console.error(`Error with API key ${apiKey}:`, err);
            // Continue to next key
          }
        }
        
        // If none of the API keys worked, use fallback
        if (!success) {
          console.warn('All API keys failed, using fallback or cached data');
          
          // Use cached data as fallback if available
          const cachedData = localStorage.getItem(CACHE_KEY);
          if (cachedData) {
            const { video } = JSON.parse(cachedData);
            setLatestVideo(video);
          } else {
            // Use hardcoded fallback as last resort
            setLatestVideo(FALLBACK_VIDEO);
          }
        }
      } catch (err) {
        console.error('YouTube API Error:', err);
        
        // Use cached data as fallback if available
        const cachedData = localStorage.getItem(CACHE_KEY);
        if (cachedData) {
          const { video } = JSON.parse(cachedData);
          setLatestVideo(video);
        } else {
          // Use hardcoded fallback as last resort
          setLatestVideo(FALLBACK_VIDEO);
          // Don't set public error, just log it
          console.error('Failed to fetch YouTube data, using fallback video');
        }
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchYouTubeData();

    // Set interval to refresh every 12 hours (twice daily)
    const intervalId = setInterval(fetchYouTubeData, CACHE_EXPIRY);
    
    return () => clearInterval(intervalId);
  }, []);

  return { latestVideo, loading, error: null }; // Always return error as null to prevent public display
}


import React, { useEffect, useRef, useState } from 'react';

const BeatStore: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [shouldLoad, setShouldLoad] = useState(false);
  
  useEffect(() => {
    // Use Intersection Observer to load iframe only when it's close to viewport
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      { rootMargin: "300px" } // Start loading when within 300px of viewport
    );
    
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    
    return () => observer.disconnect();
  }, []);
  
  return (
    <section id="beats" className="py-6 bg-white">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-4xl">
          <div 
            ref={containerRef}
            className="glass rounded-lg p-1 shadow-lg animate-pulse-subtle overflow-hidden"
          >
            {shouldLoad ? (
              <iframe 
                ref={iframeRef}
                title="BeatStars Player"
                width="100%" 
                height="1500" 
                src="https://player.beatstars.com/?storeId=27774"
                style={{ maxWidth: '1024px', border: 'none' }}
                loading="lazy"
              ></iframe>
            ) : (
              <div className="w-full h-[1500px] flex items-center justify-center bg-gray-50">
                <p className="text-gray-400">Loading beats...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeatStore;

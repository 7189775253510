
import React from 'react';
import { WavyBackground } from './ui/wavy-background';
import { useIsMobile } from '../hooks/use-mobile';
import TypewriterText from './TypewriterText';

const Header = () => {
  const isMobile = useIsMobile();
  
  return (
    // Reduced mt-16 to mt-8 to bring content closer to navigation
    <header className="relative overflow-hidden h-[35vh] min-h-[400px] flex items-center justify-center z-0 mt-8">
      {/* Gold Wave Background - Positioned more towards the top */}
      <WavyBackground 
        className="z-0"
        containerClassName="absolute inset-0 flex items-start justify-center pt-12" 
        colors={['#F5D76E', '#E5A530', '#A67C00', '#F0C350']} 
        waveWidth={35} // Increased from 20 to 35 to make waves thicker
        backgroundFill="rgba(255, 255, 255, 0.2)"
        blur={5}
        speed="fast" // Changed from "slow" to "fast" for quicker movement
        waveOpacity={0.6}
      />
      
      {/* Content Overlay - Centered text with wave in background */}
      <div className="relative z-10 mx-auto px-6 text-center">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center relative">
            {/* Added SEO-optimized heading with semantically correct h1 tag */}
            <h1 
              className={`${isMobile ? 'text-5xl' : 'text-5xl md:text-8xl lg:text-9xl'} font-display font-bold tracking-tight text-black`}
              style={{ 
                textShadow: '0 0 30px rgba(255, 255, 255, 0.8)'
              }}
              itemProp="name"
            >
              GRILLABEATS
            </h1>
            <span 
              className="text-[10px] md:text-[16px] lg:text-[18px] absolute top-0 right-[-5px] md:right-[-8px] lg:right-[-10px]"
              style={{ 
                textShadow: '0 0 10px rgba(255, 255, 255, 0.8)'
              }}
              aria-hidden="true"
            >
              ®
            </span>
          </div>
          {/* Added fixed height container to prevent layout shift when typewriter text appears */}
          <div className="h-[20px] md:h-[24px] lg:h-[30px] flex items-center justify-center">
            <p 
              className={`mt-2 ${isMobile 
                ? 'text-[10px]' 
                : 'text-[10px] md:text-[11px] lg:text-[12px]'} text-center max-w-md md:max-w-lg lg:max-w-2xl text-black/80 whitespace-nowrap`}
              style={{ 
                textShadow: '0 0 10px rgba(255, 255, 255, 0.8)'
              }}
              itemProp="description"
            >
              <TypewriterText 
                text={isMobile 
                  ? "The number 1 place for exclusive hand-crafted beats" 
                  : "The number #1 place on the internet for exclusive hand-crafted beats, since 2016"
                }
                speed={40}
                delay={800}
              />
            </p>
          </div>
        </div>
      </div>
      
      {/* Overlay Gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-white/30 via-transparent to-white z-[5]"></div>
    </header>
  );
};

export default Header;

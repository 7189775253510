
// This optional code is used to register a service worker.
// This will help with caching and making the site work offline while ensuring updates

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Define a version check to prevent serving outdated content
// Reduced frequency to prevent excessive checks and improve performance
const VERSION_CHECK_INTERVAL = 24 * 60 * 60 * 1000; // Check once per day

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
  // Only register in production and if browser supports service workers
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(import.meta.env.BASE_URL, window.location.href);
    
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    // Register the service worker after the page load to improve initial page load performance
    window.addEventListener('load', () => {
      const swUrl = `${import.meta.env.BASE_URL}service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log('Service Worker registered successfully');
      
      // Check for updates less frequently to improve performance
      let updateCheckTimer: number | null = null;
      
      const checkForUpdates = () => {
        // Only check for updates when page is not visible to avoid performance impact
        if (document.visibilityState === 'hidden') {
          registration.update();
        }
      };
      
      // Set up update check on a long interval
      updateCheckTimer = window.setInterval(checkForUpdates, VERSION_CHECK_INTERVAL);
      
      // Also check for updates when visibility changes
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'visible' && Date.now() % 5 === 0) {
          // Only occasionally check on visibility change (20% chance)
          checkForUpdates();
        }
      });
      
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log('New content is available; please refresh the page manually.');
              
              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
              
              // Never automatically update the page - let the user refresh manually
            } else {
              // At this point, everything has been precached.
              console.log('Content is cached for offline use.');
              
              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
      
      return () => {
        // Clean up interval on unmount
        if (updateCheckTimer !== null) {
          clearInterval(updateCheckTimer);
        }
        document.removeEventListener('visibilitychange', checkForUpdates);
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script', 'Cache-Control': 'no-cache' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            // Disabling automatic reload to prevent refresh loops
            console.log("Service worker not found, consider refreshing manually");
            // IMPORTANT: Removed automatic window.location.reload() that was causing loops
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

// IMPORTANT: Completely removed the controllerchange event that was causing automatic reloading

// Force update check when user performs certain actions
export function checkForUpdates() {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    // Just log that we're checking without taking drastic action
    console.log('Checking for service worker updates without forcing refresh');
  }
}


import React, { useEffect, useState, useCallback, memo } from 'react';
import { BeamsBackground } from "./ui/beams-background";
import { GradientButton } from "./ui/gradient-button";
import { Link } from 'react-router-dom';
import { SparklesText } from "./ui/sparkles-text";
import { CarouselItem, CarouselContent, Carousel } from "./ui/carousel";
import { Check, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { AnimatedCursor } from "./ui/animated-cursor";

interface StepData {
  number: number;
  title: string;
  description: string;
  image: string;
}

// Memoize the step item component to prevent unnecessary re-renders
const StepItem = memo(({ step, isActive }: { step: StepData; isActive: boolean }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 h-full">
    {/* Image side - consistent size for all images with animated cursor */}
    <div 
      className="how-it-works-image-container h-[200px] md:h-[300px] relative overflow-hidden p-4 flex items-center justify-center"
      data-step={step.number}
    >
      <img 
        src={step.image} 
        alt={step.title}
        className="w-auto h-auto object-contain rounded-xl shadow-lg"
        style={{ 
          maxHeight: "90%", 
          maxWidth: "90%",
          width: "auto",
          height: "auto"
        }} 
      />
      {/* Very minimal shadow overlay for brightest images */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/5 via-transparent to-transparent"></div>
    </div>
    
    {/* Content side */}
    <div className="p-6 flex flex-col justify-center text-left">
      <div className="flex items-center mb-3 space-x-2">
        <div className="bg-gold text-black font-bold rounded-full w-6 h-6 flex items-center justify-center text-sm">
          {step.number}
        </div>
        <h3 className="text-xl md:text-2xl font-display font-bold text-white">{step.title}</h3>
      </div>
      <p className="text-white/80 mb-4">{step.description}</p>
    </div>
  </div>
));

const HowItWorks: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoplayInterval, setAutoplayInterval] = useState<NodeJS.Timeout | null>(null);
  const [showAnimatedCursor, setShowAnimatedCursor] = useState(false);
  
  const steps: StepData[] = [
    {
      number: 1,
      title: "Browse & Select",
      description: "Explore our vast collection of premium beats and find the perfect sound for your project.",
      image: "/lovable-uploads/e5fdf6e1-2d62-4e92-aad6-9f7a8964bd9d.png", // Updated with new uploaded image
    },
    {
      number: 2,
      title: "Purchase License",
      description: "Choose the right licensing plan that fits your needs and get instant access to high-quality files.",
      image: "/lovable-uploads/bcf312e8-10e1-4464-9cee-7f3e1f31cee0.png", // Kept the same as currently in step 2
    },
    {
      number: 3,
      title: "Download & Create",
      description: "Download your beats in various formats and start creating your next hit song immediately.",
      image: "/lovable-uploads/7005927e-7c52-4aa2-a6be-377e9e8cbdd6.png", // Kept the same as currently in step 3
    }
  ];
  
  // Function to start autoplay - moved outside of the useCallback to avoid circular dependency
  const startAutoplay = () => {
    // Clear any existing interval first to avoid multiple intervals
    if (autoplayInterval) {
      clearInterval(autoplayInterval);
    }
    
    const interval = setInterval(() => {
      setActiveIndex(prev => (prev + 1) % steps.length);
    }, 3000); // 3 seconds per step
    
    setAutoplayInterval(interval);
    return interval;
  };
  
  const handleSelect = useCallback((index: number) => {
    setActiveIndex(index);
    // Restart the autoplay when a step is manually selected
    startAutoplay();
  }, []);
  
  // Setup autoplay
  useEffect(() => {
    // Always start with step 1 (index 0)
    setActiveIndex(0);
    
    // Start the autoplay
    const interval = startAutoplay();
    
    // Initialize cursor
    setShowAnimatedCursor(true);
    
    // Cleanup function
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []); // Empty dependency array so this only runs once on mount
  
  const scrollToBeats = useCallback(() => {
    const beatsSection = document.getElementById('beats');
    if (beatsSection) {
      beatsSection.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // Render button component
  const RenderButtons = memo(() => (
    <div className="flex justify-center mt-10 space-x-4 fade-in appear-animation">
      <GradientButton 
        onClick={scrollToBeats} 
        className="text-sm"
      >
        Select Beats
      </GradientButton>
      <Link to="/pricing">
        <GradientButton 
          variant="variant"
          className="text-sm pricing-button"
        >
          See Pricing
        </GradientButton>
      </Link>
    </div>
  ));
  
  return (
    <BeamsBackground intensity="strong" className="py-8">
      <div className="container mx-auto px-4 py-4 md:py-6 relative z-10">
        {/* Further reduced top spacing by changing py-12 to py-8 above and adjusting padding below */}
        <div className="text-center mb-0 relative z-20">
          <div className="inline-block px-3 py-1 rounded-full bg-gold/10 text-gold text-xs font-medium mb-2">
            Simple Process
          </div>
          <div className="mb-0 text-white">
            <SparklesText 
              text="How It Works" 
              colors={{
                first: "#F5D76E", // Gold light
                second: "#E5A530"  // Gold default
              }}
              className="text-3xl md:text-4xl font-display font-bold"
            />
          </div>
          <p className="text-white max-w-2xl mx-auto mb-0">
            Get your exclusive beats in 3 simple steps and take your project to the next level.
          </p>
        </div>
        
        {/* Removed "border border-white/5" to eliminate the white border */}
        <div className="max-w-4xl mx-auto -mt-4 rounded-xl overflow-hidden shadow-2xl relative z-10">
          <Carousel className="w-full">
            <CarouselContent className="p-0">
              {steps.map((step, index) => (
                <CarouselItem key={index} className={`p-0 w-full ${activeIndex === index ? 'block' : 'hidden'}`}>
                  <StepItem step={step} isActive={activeIndex === index} />
                </CarouselItem>
              ))}
            </CarouselContent>
            
            {/* Step Indicator - with same black background */}
            <div className="p-0 border-t border-white/10 bg-black/60">
              {/* Applying same padding top and bottom for even spacing */}
              <div className="flex items-center space-x-3 py-3">
                {steps.map((step, index) => (
                  <button
                    key={index}
                    onClick={() => handleSelect(index)}
                    className={cn(
                      "flex-1 flex items-center justify-center transition-all duration-300",
                      activeIndex === index 
                        ? "text-gold" 
                        : "text-white/60 hover:text-white/80"
                    )}
                  >
                    <div className="flex items-center space-x-2">
                      <div className={cn(
                        "rounded-full w-5 h-5 flex items-center justify-center text-xs border",
                        activeIndex === index 
                          ? "bg-gold border-gold text-black" 
                          : "border-white/40 text-white/60"
                      )}>
                        {activeIndex === index ? <Check className="w-3 h-3" /> : step.number}
                      </div>
                      <span className="text-sm font-medium text-center">{step.title}</span>
                    </div>
                    
                    {index < steps.length - 1 && (
                      <ArrowRight className={cn(
                        "w-4 h-4 ml-2",
                        activeIndex === index ? "text-gold" : "text-white/40"
                      )} />
                    )}
                  </button>
                ))}
              </div>
              
              {/* Progress Bar - with even spacing */}
              <div className="h-1 bg-white/10 rounded-full overflow-hidden mx-4 mb-3">
                <div 
                  className="h-full bg-gold transition-all duration-500 ease-in-out"
                  style={{ 
                    width: `${100 / steps.length}%`, 
                    transform: `translateX(${activeIndex * 100}%)` 
                  }}
                />
              </div>
            </div>
          </Carousel>
          
          {/* Animated cursor that works across all steps */}
          {showAnimatedCursor && (
            <AnimatedCursor 
              targetSelector=".how-it-works-image-container"
              sparkleColor="#F5D76E"
              cursorColor="#E5A530"
              cursorSize={10}
            />
          )}
        </div>
        
        {/* CTA Buttons */}
        <RenderButtons />
      </div>
    </BeamsBackground>
  );
};

export default React.memo(HowItWorks);

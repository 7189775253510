
import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { useOnScrollUp } from '../hooks/useOnScrollUp';
import { Link } from 'react-router-dom';

interface NavigationProps {
  className?: string;
  dropdownBgClass?: string; // New prop for dropdown background
}

const Navigation: React.FC<NavigationProps> = ({ 
  className, 
  dropdownBgClass = "glass" // Default to glass background
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isVisible = useOnScrollUp();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav 
      className={`fixed top-0 left-0 w-full z-50 glass transition-opacity duration-300 -mt-3 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      } ${className}`}
    >
      <div className="container mx-auto px-6">
        <div className="py-0 flex justify-between items-center">
          <a href="/" className="flex items-center">
            <img src="/lovable-uploads/a12b43ca-3e69-4e2b-ab2e-489603798a23.png" alt="BEATS Logo" className="h-28 w-auto" />
          </a>

          {/* Mobile menu button */}
          <button 
            onClick={toggleMenu} 
            className="lg:hidden rounded-full p-2 focus:outline-none focus:ring-2 focus:ring-gold transition-colors"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop menu - added About link */}
          <div className="hidden lg:flex items-center space-x-5">
            <Link to="/" className="text-sm hover:text-gold-dark transition-colors">Beats</Link>
            <div className="relative group">
              <a href="#solutions" className="text-sm hover:text-gold-dark transition-colors">Solutions</a>
              <div className={`absolute left-0 mt-2 w-48 rounded-md shadow-lg ${dropdownBgClass} invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-300`}>
                <div className="py-2">
                  <Link to="/sound-production" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">Sound Production</Link>
                  <Link to="/mix-and-master" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">Mix & Master</Link>
                  <Link to="/vfx" className="block px-4 py-2 text-sm hover:bg-white/20 transition-colors">SFX</Link>
                </div>
              </div>
            </div>
            <Link to="/about-us" className="text-sm hover:text-gold-dark transition-colors">About</Link>
            <Link to="/pricing" className="text-sm hover:text-gold-dark transition-colors">Pricing</Link>
            <Link to="/support" className="text-sm hover:text-gold-dark transition-colors">Support</Link>
          </div>
        </div>

        {/* Mobile menu - Added About link */}
        <div className={`lg:hidden absolute w-full left-0 rounded-b-lg shadow-lg bg-white transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'max-h-screen opacity-100 visible' : 'max-h-0 opacity-0 invisible'
        } overflow-hidden`}>
          <div className="px-4 py-2 space-y-3">
            <Link to="/" className="block py-2 text-sm">Beats</Link>
            <div className="space-y-1 ml-4">
              <p className="font-semibold text-sm">Solutions</p>
              <Link to="/sound-production" className="block py-1 text-xs">Sound Production</Link>
              <Link to="/mix-and-master" className="block py-1 text-xs">Mix & Master</Link>
              <Link to="/vfx" className="block py-1 text-xs">SFX</Link>
            </div>
            <Link to="/about-us" className="block py-2 text-sm">About</Link>
            <Link to="/pricing" className="block py-2 text-sm">Pricing</Link>
            <Link to="/support" className="block py-2 text-sm">Support</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;

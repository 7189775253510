
import React, { useState, useEffect, useRef } from 'react';

interface CounterItemProps {
  value: number;
  label: string;
  duration?: number;
  delay?: number;
  dailyIncrement?: number;
}

// Base initial values
export const BASE_CUSTOMER_COUNT = 29835;
export const CUSTOMER_DAILY_INCREMENT = 3;
export const BASE_DATE = '2024-06-04';

// Helper function to calculate current value based on daily increment
export const calculateCurrentValue = (baseValue: number, dailyIncrement: number = 0, baseDate: string = BASE_DATE) => {
  if (dailyIncrement > 0) {
    const baseDateTimestamp = new Date(baseDate).getTime();
    const today = new Date().getTime();
    const daysDifference = Math.floor((today - baseDateTimestamp) / (1000 * 60 * 60 * 24));
    return baseValue + (daysDifference * dailyIncrement);
  }
  return baseValue;
};

const CounterItem: React.FC<CounterItemProps> = ({ 
  value, 
  label, 
  duration = 2000,
  delay = 0,
  dailyIncrement = 0
}) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  
  // Calculate additional value based on days since base date
  const computeCurrentValue = () => {
    return calculateCurrentValue(value, dailyIncrement, BASE_DATE);
  };
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    
    if (counterRef.current) {
      observer.observe(counterRef.current);
    }
    
    return () => observer.disconnect();
  }, []);
  
  useEffect(() => {
    if (!isVisible) return;
    
    const targetValue = computeCurrentValue();
    
    const timeout = setTimeout(() => {
      let start = 0;
      const increment = Math.ceil(targetValue / 100);
      const timer = setInterval(() => {
        start += increment;
        if (start >= targetValue) {
          start = targetValue;
          clearInterval(timer);
        }
        setCount(start);
      }, duration / 100);
      
      return () => clearInterval(timer);
    }, delay);
    
    return () => clearTimeout(timeout);
  }, [value, duration, delay, isVisible, dailyIncrement]);
  
  return (
    <div 
      ref={counterRef}
      className="flex flex-col items-center"
    >
      <div className="relative">
        <span className="text-4xl md:text-5xl font-display font-bold">{count.toLocaleString()}</span>
        {/* Particles effect when counting */}
        {isVisible && (
          <>
            <span className="absolute -top-2 -right-2 h-2 w-2 rounded-full bg-gold animate-particles-float opacity-0" style={{ animationDelay: '0s' }}></span>
            <span className="absolute -top-1 -left-1 h-1 w-1 rounded-full bg-gold animate-particles-float opacity-0" style={{ animationDelay: '0.3s' }}></span>
            <span className="absolute top-1 -right-3 h-1.5 w-1.5 rounded-full bg-gold-light animate-particles-float opacity-0" style={{ animationDelay: '0.7s' }}></span>
            <span className="absolute -top-3 left-2 h-1.5 w-1.5 rounded-full bg-gold-dark animate-particles-float opacity-0" style={{ animationDelay: '1.2s' }}></span>
            <span className="absolute -bottom-1 right-2 h-1 w-1 rounded-full bg-gold animate-particles-float opacity-0" style={{ animationDelay: '1.5s' }}></span>
          </>
        )}
      </div>
      <p className="text-sm text-gray-500 mt-2">{label}</p>
    </div>
  );
};

const CounterSection: React.FC = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="glass max-w-4xl mx-auto rounded-xl shadow-lg p-8 md:p-10">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <CounterItem value={BASE_CUSTOMER_COUNT} label="Happy Customers" delay={0} dailyIncrement={CUSTOMER_DAILY_INCREMENT} />
            <CounterItem value={1235} label="Premium Beats" delay={200} />
            <CounterItem value={89} label="Major Placements" delay={400} />
            <CounterItem value={99} label="Satisfaction Rate %" delay={600} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;

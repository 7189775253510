
import React, { useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import Navigation from '../components/Navigation';
import Header from '../components/Header';
import LogoCarousel from '../components/LogoCarousel';
import BeatStore from '../components/BeatStore';
import YouTubeSection from '../components/YouTubeSection';
import CounterSection from '../components/CounterSection';
import HowItWorks from '../components/HowItWorks';
import Footer from '../components/Footer';

const Index = () => {
  // Optimized scroll reveal animation with throttling
  const handleScroll = useCallback(() => {
    const fadeElements = document.querySelectorAll('.fade-in');
    
    fadeElements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight * 0.85;
      
      if (isVisible) {
        element.classList.add('visible');
      }
    });
  }, []);
  
  useEffect(() => {
    // Initial check
    handleScroll();
    
    // Throttled scroll listener to improve performance
    let isScrolling = false;
    
    const scrollListener = () => {
      if (!isScrolling) {
        window.requestAnimationFrame(() => {
          handleScroll();
          isScrolling = false;
        });
        isScrolling = true;
      }
    };
    
    window.addEventListener('scroll', scrollListener, { passive: true });
    
    return () => window.removeEventListener('scroll', scrollListener);
  }, [handleScroll]);
  
  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>GRILLABEATS | Premium Beat Production & Exclusive Sounds</title>
        <meta name="description" content="GRILLABEATS - The #1 place for exclusive hand-crafted beats since 2016. Find premium beat production, custom sound design, and royalty-free music." />
        <meta name="keywords" content="GRILLABEATS, beat production, exclusive beats, custom beats, royalty-free music" />
      </Helmet>
      
      <Navigation />
      
      <main>
        {/* Header with reduced top padding */}
        <Header />
        
        {/* Logo carousel with proper z-index to ensure it's on top */}
        <div className="relative z-10">
          <LogoCarousel />
        </div>
        
        <section className="fade-in appear-animation pt-4" id="beats">
          <BeatStore />
        </section>
        
        <section className="fade-in appear-animation pt-0">
          <YouTubeSection />
        </section>
        
        <section className="fade-in appear-animation">
          <CounterSection />
        </section>
        
        <section className="fade-in appear-animation">
          <HowItWorks />
        </section>
      </main>
      
      <Footer />
    </div>
  );
};

export default Index;


"use client";

import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import { cn } from '@/lib/utils';

interface AnimatedCursorProps {
  /** Selector for the area where the cursor should be active */
  targetSelector: string;
  /** Color for the sparkles - defaults to gold */
  sparkleColor?: string;
  /** Size of the cursor dot */
  cursorSize?: number;
  /** Cursor dot color */
  cursorColor?: string;
  /** Whether to show sparkle trace */
  showSparkles?: boolean;
  /** Whether the cursor should be always visible within the target area */
  alwaysVisible?: boolean;
}

type SparklePoint = {
  id: number;
  x: number;
  y: number;
  size: number;
  opacity: number;
};

const AnimatedCursor: React.FC<AnimatedCursorProps> = ({
  targetSelector,
  sparkleColor = "#E5A530",
  cursorSize = 12,
  cursorColor = "#E5A530",
  showSparkles = true,
  alwaysVisible = false,
}) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const [sparkles, setSparkles] = useState<SparklePoint[]>([]);
  const lastPositionRef = useRef({ x: 0, y: 0 });
  const frameRef = useRef<number | null>(null);
  const sparkleIdRef = useRef(0);
  const targetAreasRef = useRef<HTMLElement[]>([]);
  const currentTargetRef = useRef<HTMLElement | null>(null);

  // Initialize the target area refs when component mounts or targetSelector changes
  useEffect(() => {
    const updateTargets = () => {
      // Use querySelectorAll to get all matching elements
      const elements = Array.from(document.querySelectorAll(targetSelector)) as HTMLElement[];
      targetAreasRef.current = elements;
      
      console.log(`Found ${elements.length} elements matching selector: ${targetSelector}`);
    };
    
    // Initial update
    updateTargets();
    
    // Set up a MutationObserver to watch for DOM changes
    const observer = new MutationObserver((mutations) => {
      // When DOM changes, check if our targets need updating
      updateTargets();
    });
    
    observer.observe(document.body, { 
      childList: true, 
      subtree: true,
      attributes: true,
      attributeFilter: ['class', 'style', 'id'] 
    });
    
    return () => {
      observer.disconnect();
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, [targetSelector]);

  // Create a throttled mouse move handler to improve performance
  useEffect(() => {
    let lastUpdateTime = 0;
    const THROTTLE_MS = 10; // Update at most every 10ms
    
    const handleMouseMove = (e: MouseEvent) => {
      if (targetAreasRef.current.length === 0) return;
      
      // Find which target area (if any) contains the cursor
      let activeTarget: HTMLElement | null = null;
      let relativeX = 0;
      let relativeY = 0;
      
      for (const target of targetAreasRef.current) {
        const rect = target.getBoundingClientRect();
        
        // Check if the mouse is within this target area
        const isInTarget = 
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom;
        
        if (isInTarget) {
          activeTarget = target;
          // Calculate position relative to this target area
          relativeX = e.clientX - rect.left;
          relativeY = e.clientY - rect.top;
          break; // Stop after finding the first matching target
        }
      }
      
      const isInAnyTarget = activeTarget !== null;
      currentTargetRef.current = activeTarget;
      
      // Update visibility based on whether cursor is in any target area
      if (alwaysVisible) {
        setIsVisible(true);
      } else {
        setIsVisible(isInAnyTarget);
      }
      
      if (!isInAnyTarget) return;
      
      const now = Date.now();
      if (now - lastUpdateTime < THROTTLE_MS) return;
      lastUpdateTime = now;
      
      setPosition({ x: relativeX, y: relativeY });
      
      // Only add sparkles if the cursor has moved significantly
      const distance = Math.sqrt(
        Math.pow(relativeX - lastPositionRef.current.x, 2) + 
        Math.pow(relativeY - lastPositionRef.current.y, 2)
      );
      
      if (distance > 3 && showSparkles) {
        // Add a new sparkle at the current position
        const newSparkle = {
          id: sparkleIdRef.current++,
          x: relativeX,
          y: relativeY,
          size: Math.random() * 3 + 3,
          opacity: 1,
        };
        
        setSparkles(prev => [...prev.slice(-15), newSparkle]); // Keep last 15 sparkles
      }
      
      lastPositionRef.current = { x: relativeX, y: relativeY };
    };
    
    document.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [targetSelector, showSparkles, alwaysVisible]);
  
  // Animate sparkles fading out
  useEffect(() => {
    const updateSparkles = () => {
      setSparkles(prev => 
        prev
          .map(sparkle => ({
            ...sparkle,
            opacity: sparkle.opacity - 0.03, // Fade out
            size: sparkle.size * 0.98, // Slightly shrink
          }))
          .filter(sparkle => sparkle.opacity > 0)
      );
      
      frameRef.current = requestAnimationFrame(updateSparkles);
    };
    
    frameRef.current = requestAnimationFrame(updateSparkles);
    
    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, []);
  
  if (!isVisible) return null;
  
  return (
    <>
      {/* Render sparkle trail */}
      {sparkles.map((sparkle) => (
        <motion.div
          key={sparkle.id}
          className="absolute pointer-events-none z-20"
          style={{
            left: sparkle.x,
            top: sparkle.y,
            opacity: sparkle.opacity,
          }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.2 }}
        >
          <Sparkles 
            color={sparkleColor} 
            size={sparkle.size} 
            className="animate-pulse-glow"
          />
        </motion.div>
      ))}
      
      {/* Main cursor dot */}
      <motion.div
        className={cn(
          "rounded-full pointer-events-none absolute z-30",
          "shadow-[0_0_10px_rgba(229,165,48,0.7)]" // Gold glow effect
        )}
        style={{
          left: position.x,
          top: position.y,
          backgroundColor: cursorColor,
          width: cursorSize,
          height: cursorSize,
          transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.2, 1],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: "loop"
        }}
      />
    </>
  );
};

export { AnimatedCursor };


import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';

// Enhanced subdomain redirect handler with more accurate detection and no redirects for normal paths
const handleSubdomainRedirects = () => {
  const hostname = window.location.hostname;
  console.log("Current hostname:", hostname);
  
  // Only handle actual subdomain redirects, not path-based ones
  // IMPORTANT: Strictly check for subdomains to prevent incorrect redirects
  if (hostname.includes('instagram.grillabeats.com')) {
    console.log("Redirecting to Instagram");
    window.location.href = 'https://instagram.com/grillabeats';
    return true;
  } else if (hostname.includes('youtube.grillabeats.com')) {
    console.log("Redirecting to YouTube");
    window.location.href = 'https://youtube.com/channel/UC7x_0NjD5ipVGBL5_36sdUQ?sub_confirmation=1';
    return true;
  } else if (hostname.includes('twitter.grillabeats.com')) {
    console.log("Redirecting to Twitter");
    window.location.href = 'https://twitter.com/grillabeats';
    return true;
  } else if (hostname.includes('shop.grillabeats.com')) {
    console.log("Redirecting shop subdomain to main website");
    window.location.href = 'https://grillabeats.com/';
    return true;
  } else if (hostname.includes('beatstore.grillabeats.com') || hostname.includes('beats.grillabeats.com')) {
    console.log("Redirecting to main website");
    window.location.href = 'https://grillabeats.com/';
    return true;
  }
  
  // IMPORTANT: Don't handle path-based redirects in client JS - let React Router handle them
  return false;
};

// Check for redirects before mounting app
if (!handleSubdomainRedirects()) {
  // Create root with explicit typing
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>,
  );

  // Add event listener to detect when app is visible
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      // User has come back to the page, check for updates without force refreshing
      console.log('Page is visible, checking for updates...');
      serviceWorker.checkForUpdates();
    }
  });

  // Register the service worker with update notification
  serviceWorker.register({
    onUpdate: (registration) => {
      console.log('New version available! Ready to update.');
      // Send message to service worker to skip waiting and activate new version
      if (registration && registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      // IMPORTANT: Removed automatic reload after update is available
    },
    onSuccess: (registration) => {
      console.log('Content is cached for offline use.');
    }
  });

  // Cache-control meta tags for proper behavior
  const metaTag = document.createElement('meta');
  metaTag.setAttribute('http-equiv', 'Cache-Control');
  metaTag.setAttribute('content', 'no-cache, no-store, must-revalidate');
  document.head.appendChild(metaTag);

  const pragmaTag = document.createElement('meta');
  pragmaTag.setAttribute('http-equiv', 'Pragma');
  pragmaTag.setAttribute('content', 'no-cache');
  document.head.appendChild(pragmaTag);

  const expiresTag = document.createElement('meta');
  expiresTag.setAttribute('http-equiv', 'Expires');
  expiresTag.setAttribute('content', '0');
  document.head.appendChild(expiresTag);
}


import React, { useState, useEffect } from 'react';

interface TypewriterTextProps {
  text: string;
  className?: string;
  style?: React.CSSProperties;
  speed?: number;
  delay?: number;
}

const TypewriterText: React.FC<TypewriterTextProps> = ({
  text,
  className = "",
  style = {},
  speed = 50,
  delay = 500
}) => {
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  
  useEffect(() => {
    // Reset text when component mounts or text changes
    setDisplayText('');
    
    // Add a small delay before starting the typewriter effect
    const delayTimer = setTimeout(() => {
      setIsTyping(true);
    }, delay);
    
    return () => clearTimeout(delayTimer);
  }, [text, delay]);
  
  useEffect(() => {
    if (!isTyping) return;
    
    let currentIndex = 0;
    
    const typingInterval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText(text.substring(0, currentIndex + 1));
        currentIndex++;
      } else {
        clearInterval(typingInterval);
        setIsTyping(false);
      }
    }, speed);
    
    return () => clearInterval(typingInterval);
  }, [text, speed, isTyping]);
  
  return (
    <span className={className} style={{ ...style }}>
      {displayText}
      {isTyping && <span className="animate-pulse">|</span>}
    </span>
  );
};

export default TypewriterText;


import React, { useState, useEffect } from 'react';
import { useIsMobile } from '../hooks/use-mobile';
import { motion, AnimatePresence } from 'framer-motion';

// Real company logos as images
const LOGOS = [
  { id: 1, name: 'Nike', image: '/nike.png' },
  { id: 2, name: 'Apple', image: '/apple.png' },
  { id: 3, name: 'Sony', image: '/sony.png' },
  { id: 4, name: 'Nvidia', image: '/nvidia.png' }, 
  { id: 5, name: 'Warner', image: '/warner.png' },
  { id: 6, name: 'Adidas', image: '/adidas.png' },
  { id: 7, name: 'Music', image: '/music.png' },
  { id: 8, name: 'Round', image: '/round.png' },
  { id: 9, name: 'Universal', image: '/universal.png' },
  { id: 10, name: 'Netflix', image: '/netflix.png' },
];

const LogoCarousel: React.FC = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const isMobile = useIsMobile();
  
  // Number of logos to display at once - 5 for mobile, 8 for desktop
  const displayCount = isMobile ? 5 : 8;
  
  useEffect(() => {
    // Rotate to the next logo every 3 seconds
    const intervalId = setInterval(() => {
      setPrevIndex(startIndex);
      setStartIndex((prevIndex) => (prevIndex + 1) % LOGOS.length);
    }, 3000);
    
    return () => clearInterval(intervalId);
  }, [startIndex]);
  
  // Get the current slice of logos to display
  const getVisibleLogos = () => {
    const visibleLogos = [];
    
    for (let i = 0; i < displayCount; i++) {
      const index = (startIndex + i) % LOGOS.length;
      visibleLogos.push({
        ...LOGOS[index],
        // Now mark the rightmost logo as new (the last one in our display)
        isNew: i === displayCount - 1 && startIndex !== prevIndex
      });
    }
    
    return visibleLogos;
  };

  // Enhanced futuristic animation variants for container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };
  
  // Default logo variant for existing logos
  const defaultLogoVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.3 }
    }
  };
  
  // Enhanced 3D effect for new logos with deeper perspective
  const newLogoVariants = {
    hidden: { 
      opacity: 0, 
      z: -100,
      x: 80,
      rotateY: 45,
      scale: 0.5
    },
    visible: { 
      opacity: 1, 
      z: 0,
      x: 0,
      rotateY: 0,
      scale: 1,
      transition: { 
        type: "spring",
        stiffness: 100,
        damping: 12,
        duration: 0.8
      }
    }
  };
  
  return (
    <div className="py-3 bg-white relative z-20 overflow-hidden">
      <div className="container mx-auto">
        <div className="flex justify-center" style={{ perspective: '1200px' }}>
          <motion.div
            className="flex items-center space-x-4 md:space-x-6"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            key={startIndex} // Force re-render on index change
            style={{ transformStyle: 'preserve-3d' }}
          >
            <AnimatePresence mode="sync">
              {getVisibleLogos().map((logo, i) => (
                <motion.div 
                  key={`${logo.id}-${i}`} 
                  className="flex-shrink-0"
                  variants={logo.isNew ? newLogoVariants : defaultLogoVariants}
                  initial="hidden"
                  animate="visible"
                  style={{
                    transformStyle: "preserve-3d",
                    transformOrigin: "center right",
                    perspective: "1000px"
                  }}
                >
                  <img 
                    src={logo.image}
                    alt={`${logo.name} logo`}
                    className="h-10 md:h-12 object-contain filter transition-all duration-300 hover:opacity-80"
                    style={{ 
                      transformStyle: "preserve-3d", 
                      maxWidth: "none" 
                    }}
                  />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;
